<div class="box-popup lg:max-w-[60%] flex flex-col">
    <div class="grow">
        <div class="border-b-2 border-eip-danger-400 box p-5 bg-eip-danger-100 rounded-2xl">
            <div class="flex content-center mb-4">
                <span class="grow text-md text-center font-semibold text-gray-800" [innerHTML]="'blockade.cant-place-order-title' | translate"></span>
                <app-icon (click)="closePopup()" class="cursor-pointer" name="mdi:close"></app-icon>
            </div>
            <div class="text-base text-gray-800 text-center" [innerHTML]="'blockade.cant-place-order' | translate"></div>
        </div>
    </div>
</div>
